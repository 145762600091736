var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-md-10"},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.user_profile_security_tab_view_model.updatePassword.apply(null, arguments)}},model:{value:(_vm.user_profile_security_tab_view_model.form_validity),callback:function ($$v) {_vm.$set(_vm.user_profile_security_tab_view_model, "form_validity", $$v)},expression:"user_profile_security_tab_view_model.form_validity"}},[_c('h6',{staticClass:"mt-5 mb-7 text-center primary--text"},[_vm._v(" "+_vm._s(_vm.user_profile_security_tab_view_model.translate('change_password'))+" ")]),_c('label',{staticClass:"inactive-blue--text"},[_vm._v(" "+_vm._s(_vm.user_profile_security_tab_view_model.translate('current_password.label'))+" ")]),_c('v-text-field',{staticClass:"pt-0 v-text-field--blue",attrs:{"name":"current_password","prepend-inner-icon":"mdi-lock","color":"sky-blue","type":_vm.user_profile_security_tab_view_model.inputs_config.current_password.type,"append-icon":_vm.user_profile_security_tab_view_model.inputs_config.current_password.type === 'text'
          ? 'mdi-eye'
          : 'mdi-eye-off',"rules":_vm.user_profile_security_tab_view_model.inputs_config.current_password.rules,"disabled":_vm.user_profile_security_tab_view_model.loading},on:{"click:append":function($event){_vm.user_profile_security_tab_view_model.inputs_config.current_password.type === 'text'
          ? _vm.user_profile_security_tab_view_model.inputs_config.current_password.type = 'password'
          : _vm.user_profile_security_tab_view_model.inputs_config.current_password.type = 'text'}},model:{value:(_vm.user_profile_security_tab_view_model.inputs.current_password),callback:function ($$v) {_vm.$set(_vm.user_profile_security_tab_view_model.inputs, "current_password", $$v)},expression:"user_profile_security_tab_view_model.inputs.current_password"}}),_c('p',{staticClass:"text-h7 text-center"},[_vm._v(" "+_vm._s(_vm.user_profile_security_tab_view_model.translate('new_password.title'))+" ")]),_c('label',{staticClass:"inactive-blue--text"},[_vm._v(" "+_vm._s(_vm.user_profile_security_tab_view_model.translate('new_password.label'))+" ")]),_c('v-text-field',{staticClass:"pt-0 v-text-field--blue",attrs:{"name":"new_password","prepend-inner-icon":"mdi-lock","color":"sky-blue","type":_vm.user_profile_security_tab_view_model.inputs_config.new_password.type,"append-icon":_vm.user_profile_security_tab_view_model.inputs_config.new_password.type === 'text'
          ? 'mdi-eye'
          : 'mdi-eye-off',"rules":_vm.user_profile_security_tab_view_model.inputs_config.new_password.rules,"disabled":_vm.user_profile_security_tab_view_model.loading},on:{"click:append":function($event){_vm.user_profile_security_tab_view_model.inputs_config.new_password.type === 'text'
          ? _vm.user_profile_security_tab_view_model.inputs_config.new_password.type = 'password'
          : _vm.user_profile_security_tab_view_model.inputs_config.new_password.type = 'text'},"contextmenu":function($event){$event.preventDefault();},"copy":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();}},model:{value:(_vm.user_profile_security_tab_view_model.inputs.new_password),callback:function ($$v) {_vm.$set(_vm.user_profile_security_tab_view_model.inputs, "new_password", $$v)},expression:"user_profile_security_tab_view_model.inputs.new_password"}}),_c('label',{staticClass:"inactive-blue--text"},[_vm._v(" "+_vm._s(_vm.user_profile_security_tab_view_model.translate('confirm_new_password.label'))+" ")]),_c('v-text-field',{staticClass:"pt-0 v-text-field--blue",attrs:{"name":"new_password_confirmation","prepend-inner-icon":"mdi-lock","color":"sky-blue","type":_vm.user_profile_security_tab_view_model.inputs_config.new_password_confirmation.type,"append-icon":_vm.user_profile_security_tab_view_model.inputs_config.new_password_confirmation.type
          === 'text'
            ? 'mdi-eye'
            : 'mdi-eye-off',"rules":_vm.user_profile_security_tab_view_model.inputs_config.new_password_confirmation.rules,"disabled":_vm.user_profile_security_tab_view_model.loading},on:{"click:append":function($event){_vm.user_profile_security_tab_view_model.inputs_config.new_password_confirmation.type
          === 'text'
            ? _vm.user_profile_security_tab_view_model.inputs_config
              .new_password_confirmation.type = 'password'
            : _vm.user_profile_security_tab_view_model.inputs_config
              .new_password_confirmation.type = 'text'},"contextmenu":function($event){$event.preventDefault();},"copy":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();}},model:{value:(_vm.user_profile_security_tab_view_model.inputs.new_password_confirmation),callback:function ($$v) {_vm.$set(_vm.user_profile_security_tab_view_model.inputs, "new_password_confirmation", $$v)},expression:"user_profile_security_tab_view_model.inputs.new_password_confirmation"}}),_c('label',{staticClass:"dark-grey--text"},[_vm._v(" "+_vm._s(_vm.user_profile_security_tab_view_model.translate('password_description'))+" ")]),_c('v-col',{staticClass:"mt-2 mb-5"},[_c('v-row',{staticClass:"dark-gray--text"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"d-block me-4 mb-2",attrs:{"x-small":"","color":_vm.user_profile_security_tab_view_model.is_password_min_length_valid
                  ? 'light-blue'
                  : 'secondary'}},[_vm._v(" mdi-circle ")]),_c('label',{staticClass:"caption mb-2 dark-grey--text"},[_vm._v(" "+_vm._s(_vm.user_profile_security_tab_view_model .translate('password_requirements.characters_length'))+" ")])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"d-block me-4 mb-2",attrs:{"x-small":"","color":_vm.user_profile_security_tab_view_model.one_capital_and_lowercase_letter_one_number
                  ? 'light-blue'
                  : 'secondary'}},[_vm._v(" mdi-circle ")]),_c('label',{staticClass:"caption mb-2 dark-grey--text"},[_vm._v(" "+_vm._s(_vm.user_profile_security_tab_view_model.translate( 'password_requirements.one_capital_and_lowercase_letter_one_number' ))+" ")])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"d-block me-4 mb-2",attrs:{"x-small":"","color":_vm.user_profile_security_tab_view_model.password_has_no_consecutive_characters
                  ? 'light-blue'
                  : 'secondary'}},[_vm._v(" mdi-circle ")]),_c('label',{staticClass:"caption mb-2 dark-grey--text"},[_vm._v(" "+_vm._s(_vm.user_profile_security_tab_view_model .translate('password_requirements.no_sequence'))+" ")])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"d-block me-4 mb-2",attrs:{"x-small":"","color":_vm.user_profile_security_tab_view_model
                  .password_has_no_consecutive_repeated_characters
                  ? 'light-blue'
                  : 'secondary'}},[_vm._v(" mdi-circle ")]),_c('label',{staticClass:"caption mb-2 dark-grey--text"},[_vm._v(" "+_vm._s(_vm.user_profile_security_tab_view_model.translate( 'password_requirements.no_equal_consecutive_characters' ))+" ")])],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"d-block me-4 mb-2",attrs:{"x-small":"","color":_vm.user_profile_security_tab_view_model.password_not_contains_blacklisted_words
                  ? 'light-blue'
                  : 'secondary'}},[_vm._v(" mdi-circle ")]),_c('label',{staticClass:"caption mb-2 dark-grey--text"},[_vm._v(" "+_vm._s(_vm.user_profile_security_tab_view_model .translate('password_requirements.blacklisted_words'))+" ")])],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"loading":_vm.user_profile_security_tab_view_model.loading,"disabled":!_vm.user_profile_security_tab_view_model.canContinue,"color":"accent","width":"80%","x-large":"","rounded":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.user_profile_security_tab_view_model.translate('button_change_password'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }