import i18n from '@/vue-app/plugins/i18n';
import { requiredRule, passwordFormat, RuleResponseType } from '@/vue-app/utils/form-rules';
import TYPES from '@/types';

// Application
import { GetCustomerPersonalInformationQuery }
  from '@/modules/customer/personal-information/application/queries';

// Domain
import { UpdateUserPasswordCommand } from '@/modules/user/password/application/commands';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import {
  blacklistValidator,
  containsDigitsLowerCaseAndUpperCaseValidator,
  minLengthValidator, noConsecutiveCharactersValidator, noConsecutiveRepeatedCharactersValidator,
} from '@/modules/register/domain/services/password-validators';
import Inject from '@/modules/shared/domain/di/inject';

export default class UserProfileSecurityTabViewModel {
  @Inject(TYPES.UPDATE_USER_PASSWORD_COMMAND)
  private readonly update_user_password_command!: UpdateUserPasswordCommand;

  @Inject(TYPES.GET_CUSTOMER_PERSONAL_INFORMATION_QUERY)
  private readonly get_customer_personal_information_query!: GetCustomerPersonalInformationQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly i18n_namespace = 'components.user-profile.user-profile-tabs.user-profile-security-tab';

  private readonly view!: any;

  form_validity = false;

  inputs = {
    current_password: '',
    new_password: '',
    new_password_confirmation: '',
  };

  inputs_config = {
    current_password: {
      type: 'password',
      rules: [requiredRule, passwordFormat],
    },
    new_password: {
      type: 'password',
      rules: [
        requiredRule,
        passwordFormat,
        (value: string): RuleResponseType => value !== this.inputs.current_password || i18n.t('utils.form-rules.new_password_cant_match_current').toString(),
      ],
    },
    new_password_confirmation: {
      type: 'password',
      rules: [
        requiredRule,
        passwordFormat,
        (value: string): RuleResponseType => value === this.inputs.new_password || i18n.t('utils.form-rules.confirm_password_match').toString(),
      ],
    },
  };

  loading = false;

  user_email = '';

  public constructor(view: any) {
    this.view = view;
  }

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )

  initialize = async () => {
    await this.loadUserAccountInformation();
  }

  get is_password_min_length_valid() {
    return (this.inputs.new_password) ? minLengthValidator(this.inputs.new_password) : false;
  }

  get one_capital_and_lowercase_letter_one_number() {
    return (this.inputs.new_password)
      ? containsDigitsLowerCaseAndUpperCaseValidator(this.inputs.new_password) : false;
  }

  get password_not_contains_blacklisted_words() {
    const email_id = this.user_email.split('@')[0];
    return (this.inputs.new_password)
      ? blacklistValidator(this.inputs.new_password, [email_id]) : false;
  }

  get password_has_no_consecutive_characters() {
    return (this.inputs.new_password)
      ? noConsecutiveCharactersValidator(this.inputs.new_password) : false;
  }

  get password_has_no_consecutive_repeated_characters() {
    return (this.inputs.new_password)
      ? noConsecutiveRepeatedCharactersValidator(this.inputs.new_password) : false;
  }

  get canContinue() {
    return (
      this.form_validity
      && this.is_password_min_length_valid
      && this.one_capital_and_lowercase_letter_one_number
      && this.password_not_contains_blacklisted_words
      && this.password_has_no_consecutive_characters
      && this.password_has_no_consecutive_repeated_characters
    );
  }

  loadUserAccountInformation = async () => {
    try {
      const { email } = await this.get_customer_personal_information_query.execute();
      this.user_email = email;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_user_account_information'));
    }
  }

  updatePassword = async () => {
    try {
      this.loading = true;
      await this.update_user_password_command.execute(this.inputs);
      this.view.$refs.form.reset();
      this.message_notifier.showSuccessNotification(this.translate('password_updated'));
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.cant_update_password'));
    } finally {
      this.loading = false;
    }
  }
}
