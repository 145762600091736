
























































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { VForm } from '@/vue-app/types/vuetify';
import UserProfileSecurityTabViewModel
  from '@/vue-app/view-models/components/user-profile/user-profile-tabs/user-profile-security-tab-view-model';

@Component({ name: 'UserProfileSecurityTab' })
export default class UserProfileSecurityTab extends Vue {
  $refs!: {
    form: VForm;
  };

  user_profile_security_tab_view_model = Vue.observable(new UserProfileSecurityTabViewModel(this));

  created() {
    this.user_profile_security_tab_view_model.initialize();
  }
}
